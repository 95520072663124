import React from 'react'
import { Table } from 'semantic-ui-react'
import Context from './context'
import SubCellTable from './subCellTable'

function TableExamplePagination(props) {
    return (
        <Context.Consumer >
            {
                ctx => {
                    return (<Table  celled className="myTable">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell className = "cellClass" rowSpan='2'>#</Table.HeaderCell>
                                <Table.HeaderCell className = "cellClass" rowSpan='2'>المركبة</Table.HeaderCell>
                                <Table.HeaderCell className = "cellClass" rowSpan='2'>الحالة</Table.HeaderCell>
                                <Table.HeaderCell className = "cellClass" colSpan='4'>المركز البلدي</Table.HeaderCell>
                                
                                <Table.HeaderCell className = "cellClass" rowSpan='2'>المحرك</Table.HeaderCell>
                                <Table.HeaderCell className = "cellClass" rowSpan='2'>التوقفات</Table.HeaderCell>
                                <Table.HeaderCell className = "cellClass" colSpan='3'>المكبس</Table.HeaderCell>
                                <Table.HeaderCell className = "cellClass" colSpan='3'>المحطة التحويلية</Table.HeaderCell>


                            </Table.Row>
                            <Table.Row>
                                <Table.HeaderCell className = "cellClass">الدخول</Table.HeaderCell>
                                <Table.HeaderCell className = "cellClass">الخروج</Table.HeaderCell>
                                <Table.HeaderCell className = "cellClass">الفترة</Table.HeaderCell>
                                <Table.HeaderCell className = "cellClass">المسافة - كم</Table.HeaderCell>

                                <Table.HeaderCell>الدخول</Table.HeaderCell>
                                <Table.HeaderCell>الخروج</Table.HeaderCell>
                                <Table.HeaderCell>المدة</Table.HeaderCell>
                                <Table.HeaderCell>الدخول</Table.HeaderCell>
                                <Table.HeaderCell>الخروج</Table.HeaderCell>
                                <Table.HeaderCell>المدة</Table.HeaderCell>

                            </Table.Row>

                        </Table.Header>

                        <Table.Body>

                            {
                                ctx.value.selectedTargetType.id === 0 ? // means unit
                                
                                    ctx.value.newData.length > 0 ?
                                        ctx.value.newData.map(function (value, index) {
                                            return (
                                                <Table.Row
                                                style={value.status === "غ"?{color:'#ffffff',backgroundColor:"#ff0000"}:null}
                                                key={index}>
                                                    <Table.Cell key={index + value.id + 'a'}>{index + 1}</Table.Cell>
                                                    <Table.Cell className="cellClass" key={index + value.id + 'b'}>{value.unitName}</Table.Cell>
                                                    <Table.Cell 
                                                     
                                                     className="cellClass" key={index + value.id + 'b2'}>{value.status}</Table.Cell>
                                                  
                                                    <Table.Cell className="cellClass" key={index + value.id + 'c'}>
                                                    <SubCellTable data={value.visits.mar.details.map(e=> e.in)}></SubCellTable>
                                                   
                                                    </Table.Cell>
                                                    <Table.Cell className="cellClass" key={index + value.id + 'd'}>
                                                        <SubCellTable data={value.visits.mar.details.map(e=> e.out)}></SubCellTable>
                                                    </Table.Cell>
                                                    <Table.Cell className="cellClass" key={index + value.id + 'e'}>
                                                        <SubCellTable data={value.visits.mar.details.map(e=> e.duration)}></SubCellTable>
                                                    </Table.Cell>
                                                    <Table.Cell className="cellClass" key={index + value.id + 'f'}>
                                                        <SubCellTable data={value.visits.mar.details.map(e=> e.km)}></SubCellTable>
                                                    </Table.Cell>
                                                    <Table.Cell className="cellClass" key={index + value.id + 'g'}>
                                                     {value.visits.mar.eh}
                                                    </Table.Cell>
                                                    <Table.Cell className="cellClass" key={index + value.id + + 'h'}>
                                                     {value.visits.mar.parkings}
                                                    </Table.Cell>

                                                     <Table.Cell className="cellClass" key={index + value.id + 'i'}>
                                                        <SubCellTable data={value.visits.mak.details.map(e=> e.in)}></SubCellTable>
                                                    </Table.Cell>
                                                    <Table.Cell className="cellClass" key={index + value.id + 'j'}>
                                                        <SubCellTable data={value.visits.mak.details.map(e=> e.out)}></SubCellTable>
                                                    </Table.Cell>
                                                    <Table.Cell className="cellClass" key={index + value.id + 'k'}>
                                                        <SubCellTable data={value.visits.mak.details.map(e=> e.duration)}></SubCellTable>
                                                    </Table.Cell>

                                                     <Table.Cell className="cellClass" key={index + value.id + 'l'}>
                                                        <SubCellTable data={value.visits.mah.details.map(e=> e.in)}></SubCellTable>
                                                    </Table.Cell>
                                                    <Table.Cell className="cellClass" key={index + value.id + 'm'}>
                                                        <SubCellTable data={value.visits.mah.details.map(e=> e.out)}></SubCellTable>
                                                    </Table.Cell>
                                                    <Table.Cell className="cellClass" key={index + value.id + 'n'}>
                                                        <SubCellTable data={value.visits.mah.details.map(e=> e.duration)}></SubCellTable>
                                                    </Table.Cell>
                                                </Table.Row>
                                            )
                                        })
                                        :
                                        null

                                    : // if selected type id is 1 means for group
                                    ctx.value.newData.length > 0 ?
                                        ctx.value.newData.map(function (value, index) {
                                            return (
                                                <Table.Row
                                                style={value.status === "غ"?{color:'#ffffff',backgroundColor:"#ff0000"}:null}
                                                key={index}>
                                                    <Table.Cell key={index + value.id + 'a'}>{index + 1}</Table.Cell>
                                                    <Table.Cell className="cellClass" key={index + value.id + 'b'}>{value.unitName}</Table.Cell>
                                                    <Table.Cell className="cellClass" key={index + value.id + 'b2'}>
                                                    {value.status}
                                                    </Table.Cell>
                                                 
                                                    <Table.Cell className="cellClass" key={index + value.id + 'c'}>
                                                    <SubCellTable data={value.visits.mar.details.map(e=> e.in)}></SubCellTable>
                                                   
                                                    </Table.Cell>
                                                    <Table.Cell className="cellClass" key={index + value.id + 'd'}>
                                                        <SubCellTable data={value.visits.mar.details.map(e=> e.out)}></SubCellTable>
                                                    </Table.Cell>
                                                    <Table.Cell className="cellClass" key={index + value.id + 'e'}>
                                                        <SubCellTable data={value.visits.mar.details.map(e=> e.duration)}></SubCellTable>
                                                    </Table.Cell>
                                                    <Table.Cell className="cellClass" key={index + value.id + 'f'}>
                                                        <SubCellTable data={value.visits.mar.details.map(e=> e.km)}></SubCellTable>
                                                    </Table.Cell>
                                                    <Table.Cell className="cellClass" key={index + value.id + 'g'}>
                                                     {value.visits.mar.eh}
                                                    </Table.Cell>
                                                    <Table.Cell className="cellClass" key={index + value.id + + 'h'}>
                                                     {value.visits.mar.parkings}
                                                    </Table.Cell>

                                                     <Table.Cell className="cellClass" key={index + value.id + 'i'}>
                                                        <SubCellTable data={value.visits.mak.details.map(e=> e.in)}></SubCellTable>
                                                    </Table.Cell>
                                                    <Table.Cell className="cellClass" key={index + value.id + 'j'}>
                                                        <SubCellTable data={value.visits.mak.details.map(e=> e.out)}></SubCellTable>
                                                    </Table.Cell>
                                                    <Table.Cell className="cellClass" key={index + value.id + 'k'}>
                                                        <SubCellTable data={value.visits.mak.details.map(e=> e.duration)}></SubCellTable>
                                                    </Table.Cell>

                                                     <Table.Cell className="cellClass" key={index + value.id + 'l'}>
                                                        <SubCellTable data={value.visits.mah.details.map(e=> e.in)}></SubCellTable>
                                                    </Table.Cell>
                                                    <Table.Cell className="cellClass" key={index + value.id + 'm'}>
                                                        <SubCellTable data={value.visits.mah.details.map(e=> e.out)}></SubCellTable>
                                                    </Table.Cell>
                                                    <Table.Cell className="cellClass" key={index + value.id + 'n'}>
                                                        <SubCellTable data={value.visits.mah.details.map(e=> e.duration)}></SubCellTable>
                                                    </Table.Cell>
                                                </Table.Row>
                                            )
                                        })
                                        :
                                        null

                            }
                        </Table.Body>

                        <Table.Footer>
                            <Table.Row>

                            </Table.Row>
                        </Table.Footer>
                    </Table>)
                }
            }
        </Context.Consumer>
    )
}

export default TableExamplePagination