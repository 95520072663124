import React from "react";
import { BrowserRouter, Route, Switch, Router } from "react-router-dom";
import "./App.css";
// import XLSX from 'xlsx';
import Context from "./components/context";
import MyNav from "./components/MyNav";
import { saveAs } from "file-saver";
import { tsConstructorType } from "@babel/types";
const ExcelJS = require("exceljs");

const fileJSON = require("./assets/files/constData.json");

/* global wialon */
/* global xlsx */

const RES_NAME = fileJSON.resourceName;
const TARGET_TYPES = fileJSON.targetTypes;
class App extends React.Component {
  constructor(props) {
    // console.log(fileJSON.targetTypes[1])
    super(props);
    let startDate = new Date();
    startDate.setHours(0, 0, 0, 0);
    let endDate = new Date();
    endDate.setHours(23, 59, 59, 59);

    this.state = {
      token:
        "bc98c8124aff4fc99f29c16781eaedd028D93FF0D0B45FE517D6DA984A38A9D20E9DC357", // test app
      isAuthorized: false,
      username: "",
      res: [],
      logging: true,
      showAlert: false,
      alertVariant: "success",
      error: false,
      alertMSG: "",
      resultJSON: [],
      selectedRes: {},
      selectedReport: {},
      selectedObject: {},
      units: [],
      unitGroups: [],
      executing: false,
      exporting: false,
      fromDateVal: startDate,
      toDateVal: endDate,
      fromDateValUnix: this.convertSATToUnix(startDate),
      toDateValUnix: this.convertSATToUnix(endDate),
      targetTypes: TARGET_TYPES,
      selectedTargetType: fileJSON.targetTypes[1], // group is default
      newData: [], // for unit [{},{},....] , for group [[{},{},....],[],[]]
      tempData: [], // to hold prepared object arrays of final project
      overlayText: "جاري تسجيل الدخول...",
      loginMode: fileJSON.loginMode,
      isTried: false,
      dataTable: [],
      zones: [],
      filteredZones: [],
      zonesGroups: [],
      selectedZone: {},
      zoneGroup: {},
      isReady: false
    };
    this.fromDateOnChange = this.fromDateOnChange.bind(this);
    this.toDateOnChange = this.toDateOnChange.bind(this);
    this.propOpenFunc = this.propOpenFunc.bind(this);
    this.execute = this.execute.bind(this);
    this.onTargetTypeChange = this.onTargetTypeChange.bind(this);
    this.onUnitChange = this.onUnitChange.bind(this);
    this.onUnitGroupChange = this.onUnitGroupChange.bind(this);
    this.export = this.export.bind(this);
    this.setAuthHash = this.setAuthHash.bind(this);
    this.start = this.start.bind(this);
    this.auth1 = this.auth.bind(this);
    this.onZoneChange = this.onZoneChange.bind(this);
  }

  render() {
    return (
      <BrowserRouter basename="/">
        <Context.Provider
          value={{
            value: this.state,
            actions: {
              fromDateOnChange: this.fromDateOnChange,
              toDateOnChange: this.toDateOnChange,
              propOpen: this.propOpenFunc,
              execute: this.execute,
              onTargetTypeChange: this.onTargetTypeChange,
              onUnitChange: this.onUnitChange,
              onUnitGroupChange: this.onUnitGroupChange,
              export: this.export,
              setAuthHash: this.setAuthHash,
              start: this.start,
              auth: this.auth1,
              onZoneChange: this.onZoneChange
            }
          }}
        >
          <Switch>
            <Route path="/start" exact component={MyNav} />
            <Route path="/" component={MyNav} />
          </Switch>
        </Context.Provider>
      </BrowserRouter>
    );
  }
  async start(e) {
    //console.log('hash updated now... going to /')
    //await this.setState({authHash:hash})
    window.location.href = "/?authHash=09fe3bad5c5671d8c13b9b21bfc6e787";
  }

  async componentDidMount() {
    // this.auth()
  }

  setAuthHash(aHashVal) {
    // console.log('h updated')
    this.setState({ authHash: aHashVal });
    // console.log('s c')
  }
  async auth1(e) {
    // console.log('passed hash')
    // console.log(e)

    this.auth(e);
  }
  
  // async auth(hashOrToken) {
  //   //await this.setState({ token: JSON.parse(localStorage.getItem('wenk_helper_token')), logging: true })
  //   wialon.core.Session.getInstance().initSession("https://hst-api.wialon.com");
  //   // Try to login when component mount
  //   if (this.state.loginMode === "token") {
  //     // console.log('mode : T')
  //     this.loginWithToken();
  //   }
  //   if (this.state.loginMode === "authHash") {
  //     // console.log('mode : H')
  //     this.loginWithHash(hashOrToken);
  //   }
  // }

  async auth(data) {
    const hashOrToken = data.hashOrToken
    const baseUrl = data.baseUrl
    //await this.setState({ token: JSON.parse(localStorage.getItem('wenk_helper_token')), logging: true })
    wialon.core.Session.getInstance().initSession(baseUrl);
    // Try to login when component mount
    if (this.state.loginMode === "token") {
      console.log('mode : T')
      this.loginWithToken(baseUrl);
    }
    if (this.state.loginMode === "authHash") {
      console.log('mode : H')
      this.loginWithHash(hashOrToken, baseUrl);
    }
  }



  loginWithToken(baseUrl) {
    let that = this;
    console.log(baseUrl)
    wialon.core.Session.getInstance().initSession(baseUrl);

    wialon.core.Session.getInstance().loginToken(
      this.state.token,
      "", // try to login
      async code => {
        // login callback
        // if error code - print error message
        this.setState({ isTried: true });
        if (code) {
          that.setState({
            showAlert: true,
            alertVariant: "error",
            logging: false,
            isAuthorized: false,
            alertMSG: true,
            error: true,
            alertMSG:
              code === 4
                ? "دخول غير مخول"
                : wialon.core.Errors.getErrorText(code)
          });
          return;
        }
        // console.log('authorized')
        await that.setState({
          alertVariant: "success",
          overlayText: "جاري تحميل البيانات....",
          logging: false,
          executing: false,
          error: false,
          showAlert: true,
          isAuthorized: true,
          alertMSG:
            wialon.core.Session.getInstance()
              .getCurrUser()
              .getName() + " : تم تسجيل الدخول بنجاح!",
          username: wialon.core.Session.getInstance().getCurrUser(),
          isTried: true
        });

        await that.init();
      }
    );
  }


  loginWithHash(hash, baseUrl) {
    wialon.core.Session.getInstance().initSession(baseUrl);

    let that = this;
    wialon.core.Session.getInstance().loginAuthHash(
      hash,
      "", // try to login
      async code => {
        this.setState({ isTried: true });

        // login callback
        // if error code - print error message
        if (code) {
          that.setState({
            showAlert: true,
            alertVariant: "error",
            logging: false,
            isAuthorized: false,
            alertMSG: true,
            error: true,
            alertMSG: wialon.core.Errors.getErrorText(code)
          });
          return;
        }
        that.setState({
          alertVariant: "success",
          overlayText: "جاري تحميل البيانات....",
          logging: false,
          executing: false,
          error: false,
          showAlert: true,
          isAuthorized: true,
          alertMSG:
            wialon.core.Session.getInstance()
              .getCurrUser()
              .getName() + " : تم تسجيل الدخول بنجاح!",
          username: wialon.core.Session.getInstance().getCurrUser(),
          isTried: true
        });

        await that.init();
      }
    );
  }

  init() {
    // console.log('start init')
    let that = this;
    var sess = wialon.core.Session.getInstance();

    sess.loadLibrary("resourceReports");
    sess.loadLibrary("resourceZones");
    sess.loadLibrary("resourceZoneGroups");

    // flags to specify what kind of data should be returned
    let res_flags =
      wialon.item.Item.dataFlag.base |
      wialon.item.Resource.dataFlag.reports |
      wialon.item.Resource.dataFlag.zoneGroups |
      wialon.item.Resource.dataFlag.zones; // 64 bit OR
    let unit_flags = 1;
    let unitGroup_flags = 1;

    sess.updateDataFlags(
      // load items to current session
      [
        { type: "type", data: "avl_resource", flags: res_flags, mode: 0 },
        { type: "type", data: "avl_unit_group", flags: unit_flags, mode: 0 },
        { type: "type", data: "avl_unit", flags: unitGroup_flags, mode: 0 }
      ], // Items specification

      function (code) {
        // updateDataFlags callback
        if (code) {
          // console.log("Error: " + wialon.core.Errors.getErrorText(code));
          return; // exit if error code
        }

        const resourcesArr = sess.getItems("avl_resource"); // get loaded 'avl_resource's items
        that.setState({ res: resourcesArr });
        that.findResource(resourcesArr);

        const unitGroups = sess.getItems("avl_unit_group");
        const units = sess.getItems("avl_unit");
        // console.log(units)
        // console.log(unitGroups)
        if (unitGroups.length === 0 && units.length === 0) {
          that.setState({
            showAlert: true,
            error: true,
            alertMSG: "لا توجد مجموعات او مركبات!",
            alertVariant: "error"
          });
          return;
        }

        that.setState({
          units: units,
          unitGroups: unitGroups,
          overlayText: ""
        });
      }
    );
  }

  findResource(res) {
    if (RES_NAME === "*") {
      // console.log(res)

      for (let i = 0; i < res.length; i++) {
        // console.log('res is ')
        // console.log(res[i])

        let that = this;
        if (res[i].$$user_reports) {
          for (let [key, value] of Object.entries(res[i].$$user_reports)) {
            if (value.n === that.state.selectedTargetType.reportName) {
              // default selected target type should be group
              // console.log('report catched : ' + value.n)
              // console.log('zones is ')
              let zArr = that.convertObjToArr(res[i].getZones());
              let zgArr = that.convertObjToArr(res[i].getZonesGroups());
              // console.log('zones groups is')
              // console.log(zgArr)
              let zg = that.findZoneGroup(zgArr);
              // console.log('zone group found ?')
              // console.log(zg)
              if (!zg) {
                this.setState({
                  exporting: false,
                  overlayText: "",
                  showAlert: true,
                  error: true,
                  alertVariant: "error",
                  alertMSG: "لم يتم ايجاد مجموعة المراكز!"
                });
                return;
              }

              let fz = that.filterZones(zg.zns, zArr);
              if (!fz) {
                this.setState({
                  exporting: false,
                  overlayText: "",
                  showAlert: true,
                  error: true,
                  alertVariant: "error",
                  alertMSG: "لم يتم ايجاد مجموعة المراكز!"
                });
                return;
              }
              that.setState({
                filteredZones: fz,
                zones: zArr,
                zonesGroups: zgArr,
                zoneGroup: zg,
                selectedRes: res[i],
                selectedReport: value
              });

              break;
            }
          }
        }
      }
      // console.log('watch selected res and report')
    }
  }

  findZoneGroup(groups) {
    let group = null;
    for (let i = 0; i < groups.length; i++) {
      if (groups[i].n === fileJSON.zonesGroupName) {
        group = groups[i];
        break;
      }
    }
    return group;
  }
  filterZones(zns, zones) {
    let fz = [];

    for (let i = 0; i < zns.length; i++) {
      let index = zones.findIndex(function (e) {
        return zns[i] === e.id;
      });
      if (index >= 0) {
        fz.push(zones[index]);
      }
    }
    // console.log('filtered zones are : ')
    // console.log(fz)
    return fz;
  }

  convertObjToArr(obj) {
    let arr = [];
    for (let [key, value] of Object.entries(obj)) {
      arr.push(value);
    }
    // console.log('generated array is : ')
    // console.log(arr)
    return arr;
  }
  execute(e) {
    if (
      Object.entries(this.state.selectedRes).length === 0 &&
      this.state.selectedRes.constructor === Object
    ) {
      this.setState({
        showAlert: true,
        error: true,
        alertMSG: "يرجى اختيار المصدر",
        alertVariant: "error"
      });
      return;
    }
    if (
      Object.entries(this.state.selectedReport).length === 0 &&
      this.state.selectedReport.constructor === Object
    ) {
      this.setState({
        showAlert: true,
        error: true,
        alertMSG: "يرجى اختيار التقرير",
        alertVariant: "error"
      });
      return;
    }
    if (
      Object.entries(this.state.selectedObject).length === 0 &&
      this.state.selectedObject.constructor === Object
    ) {
      this.setState({
        showAlert: true,
        error: true,
        alertMSG: "يرجى اختيار الهدف",
        alertVariant: "error"
      });
      return;
    }
    if (
      Object.entries(this.state.selectedZone).length === 0 &&
      this.state.selectedZone.constructor === Object
    ) {
      this.setState({
        showAlert: true,
        error: true,
        alertMSG: "يرجى اختيار المنطقة",
        alertVariant: "error"
      });
      return;
    }
    if (this.state.fromDateValUnix === "" || this.state.toDateValUnix === "") {
      this.setState({
        showAlert: true,
        error: true,
        alertMSG: " يرجى اختيار الفترة اولاً",
        alertVariant: "error"
      });
      return;
    }
    let fromDay = this.state.fromDateVal.toLocaleDateString().split("/")[1];
    let toDay = this.state.toDateVal.toLocaleDateString().split("/")[1];
    if (toDay !== fromDay) {
      this.setState({
        showAlert: true,
        error: true,
        alertMSG: "فترة التقرير يجب ان تكون في نفس اليوم!",
        alertVariant: "error"
      });
      return;
    }

    let interval = {
      from: this.state.fromDateValUnix,
      to: this.state.toDateValUnix,
      flags: wialon.item.MReport.intervalFlag.absolute
    };
    let that = this;
    let sess = wialon.core.Session.getInstance();
    let resou = sess.getItem(this.state.selectedRes._id);
    let template = resou.getReport(this.state.selectedReport.id);

    // first i need to set local datetime

    this.prepareTempData(this.state.selectedObject);
    this.setState({
      isReady: false,
      executing: true,
      overlayText:
        "جاري تنفيذ التقرير... يرجى الانتظار... قد تستغرف عملية التنفيذ اكثر من 3 دقائق اذا كانت الفترة المحددة طويلة"
    });
    let localObj = {
      flags: 0,
      formatDate: "%25Y-%25m-%25E %25H:%25M:%25S"
    };
    sess.getRenderer().setLocale(134228528, "en", localObj, function (code) {
      if (code) {
        that.setState({
          showAlert: true,
          error: true,
          alertMSG: "جدول البيانات فارغ, لا توجد بيانات",
          alertVariant: "error",
          executing: false,
          overlayText: "",
          isReady: false
        });
        return;
      }
      resou.execReport(
        template,
        that.state.selectedObject._id,
        0,
        interval,
        function (code, data) {
          if (code) {
            that.setState({
              showAlert: true,
              error: true,
              alertMSG: wialon.core.Errors.getErrorText(code),
              alertVariant: "error"
            });
            return;
          }
          if (!data.getTables().length) {
            that.setState({
              showAlert: true,
              error: true,
              alertMSG: "جدول البيانات فارغ, لا توجد بيانات",
              alertVariant: "error",
              executing: false,
              overlayText: "",
              isReady: false
            });
            return;
          }
          // console.log(that.state)
          // that.setState({newData:[],tempData:[], overlayText: 'جاري بناء التقرير' })

          that.preBuild(data);
        }
      );
    });
  }

  preBuild(dataTable) {
    // console.log('data table is : ')
    // console.log(dataTable)
    let tables = dataTable.getTables();
    // console.log(tables)
    // console.log('selected ')
    // console.log(this.state.selectedZone)
    // fetch visits table for specific selected zone
    let that = this;
    let visitsTableIndex = tables.findIndex(function (e) {
      return (
        e.label.includes("زيارات") &&
        e.label.includes(that.state.selectedZone.n)
      );
    });
    let visitsTable = {};
    if (visitsTableIndex >= 0) {
      visitsTable = tables[visitsTableIndex];
    }
    let parkingsTableIndex = tables.findIndex(function (e) {
      return (
        e.label.includes("التوقفات") &&
        e.label.includes(that.state.selectedZone.n)
      );
    });
    let parkingsTable = {};
    if (parkingsTableIndex >= 0) {
      parkingsTable = tables[parkingsTableIndex];
    }
    let ehTableIndex = tables.findIndex(function (e) {
      return (
        e.label.includes("المحرك") &&
        e.label.includes(that.state.selectedZone.n)
      );
    });
    let ehTable = {};
    if (ehTableIndex >= 0) {
      ehTable = tables[ehTableIndex];
    }

    let makbasTableIndex = tables.findIndex(function (e) {
      return e.label.includes("زيارات المكبس");
    });
    let makbasTable = {};
    if (makbasTableIndex >= 0) {
      makbasTable = tables[makbasTableIndex];
    }

    let mahataTableIndex = tables.findIndex(function (e) {
      return e.label.includes("زيارات المحطة التحويلية");
    });
    let mahataTable = {};
    if (mahataTableIndex >= 0) {
      mahataTable = tables[mahataTableIndex];
    }
    // if(visitsTableIndex === -1 || parkingsTableIndex === -1 || ehTableIndex  === -1 || makbasTableIndex === -1 || mahataTableIndex === -1)
    // {
    //   that.setState({ showAlert: true, error: true,overlayText:'يرجى اعادة المحاولة, قم باعادة تحميل الصفحة',alertMSG:'يرجى التأكد من وجود الجدول الخاص بالمنطقة التي قمت باختيارها', alertVariant: 'error' })
    //   return;

    // }
    let obj = {
      visit: {
        table: visitsTable,
        index: visitsTableIndex
      },
      parking: {
        table: parkingsTable,
        index: parkingsTableIndex
      },
      eh: {
        table: ehTable,
        index: ehTableIndex
      },
      makbas: {
        table: makbasTable,
        index: makbasTableIndex
      },
      mahata: {
        table: mahataTable,
        index: mahataTableIndex
      }
    };

    this.build(dataTable, obj);
  }
  build(data, obj) {
    // console.log(' passed tables with indexes object ')
    // console.log(obj)
    let visitsArr = [];
    let parkingsArr = [];
    let ehArr = [];
    let makbasArr = [];
    let mahataArr = [];
    let that = this;

    let reqObj = {
      type: "range",
      data: {
        from: 0,
        to: obj.visit.table.rows,
        level: 5,
        flat: 0,
        rawValues: 0
      }
    };

    data.selectRows(obj.visit.index, reqObj, function (code, col) {
      if (code && obj.visit.index !== -1) {
        // means the error is real, then stop the detching
        that.setState({
          showAlert: true,
          error: true,
          alertMSG: wialon.core.Errors.getErrorText(code),
          alertVariant: "error"
        });
        return;
      }

      visitsArr = col;
      // fetch again
      reqObj = {
        type: "range",
        data: {
          from: 0,
          to: obj.parking.table.rows,
          level: 5,
          flat: 0,
          rawValues: 0
        }
      };
      data.selectRows(obj.parking.index, reqObj, function (code2, col2) {
        if (code2 && obj.parking.index !== -1) {
          that.setState({
            showAlert: true,
            error: true,
            alertMSG: wialon.core.Errors.getErrorText(code2),
            alertVariant: "error"
          });
          return;
        }
        parkingsArr = col2;
        // fetch again
        reqObj = {
          type: "range",
          data: {
            from: 0,
            to: obj.eh.table.rows,
            level: 5,
            flat: 0,
            rawValues: 0
          }
        };
        data.selectRows(obj.eh.index, reqObj, function (code3, col3) {
          if (code3 && obj.eh.index !== -1) {
            that.setState({
              showAlert: true,
              error: true,
              alertMSG: wialon.core.Errors.getErrorText(code3),
              alertVariant: "error"
            });
            return;
          }
          ehArr = col3;
          // console.log('eh  col')
          // console.log(ehArr)

          reqObj = {
            type: "range",
            data: {
              from: 0,
              to: obj.makbas.table.rows,
              level: 5,
              flat: 0,
              rawValues: 0
            }
          };
          data.selectRows(obj.makbas.index, reqObj, function (code4, col4) {
            if (code4 && obj.makbas.index !== -1) {
              that.setState({
                showAlert: true,
                error: true,
                alertMSG: wialon.core.Errors.getErrorText(code4),
                alertVariant: "error"
              });
              return;
            }
            makbasArr = col4;
            // console.log('makbas col')
            // console.log(makbasArr)

            reqObj = {
              type: "range",
              data: {
                from: 0,
                to: obj.mahata.table.rows,
                level: 5,
                flat: 0,
                rawValues: 0
              }
            };
            data.selectRows(obj.mahata.index, reqObj, function (code5, col5) {
              if (code5 && obj.mahata.index !== -1) {
                that.setState({
                  showAlert: true,
                  error: true,
                  alertMSG: wialon.core.Errors.getErrorText(code5),
                  alertVariant: "error"
                });
                return;
              }
              mahataArr = col5;
              // console.log('mahata col')
              // console.log(mahataArr)

              // now list all fetched tables
              // console.log('now listing all fetched tables')
              // console.log('visits')
              // console.log(visitsArr)
              // console.log('parkkings')
              // console.log(parkingsArr)

              // console.log('engine hours')
              // console.log(ehArr)

              // console.log('mak arr')
              // console.log(makbasArr)

              // console.log('mahata')
              // console.log(mahataArr)
              // console.log('now start filling them')
              // // fill visits
              if (that.state.selectedTargetType.id === 1) {
                // groups

                that.fillVisits(visitsArr);
                that.fillParkings(parkingsArr);
                that.fillEH(ehArr);
                that.fillMakbas(makbasArr);
                that.fillMahata(mahataArr);
                that.setState({
                  executing: false,
                  overlayText: "",
                  isReady: true
                });
              }
              if (that.state.selectedTargetType.id === 0) {
                // unit
                that.fillVisitsForUnit(obj.visit.table.total, visitsArr); // data is datatable
                that.fillParkingsForUnit(obj.parking.table.total, parkingsArr); // data is datatable
                that.fillEHForUnit(obj.eh.table.total, ehArr); // data is datatable
                that.fillMakbasForUnit(makbasArr); // data is datatable
                that.fillMahataForUnit(mahataArr); // data is datatable
                that.setState({
                  executing: false,
                  overlayText: "",
                  isReady: true
                });
              }
              that.calculateStatus();
            });
          });
        });
      });
    });
  }

  fillVisits(visitsArr) {
    // console.log('visits array is ')
    // console.log(visitsArr)
    //console.log('in vis arr, temp data is : ')
    //console.log(this.state.tempData)
    let finalArr = this.state.tempData; // get temp array data
    // console.log('final arr before start visits proccessing is ')
    // console.log(finalArr)
    // console.log('1- fill visits array')
    // console.log(visitsArr)
    for (let i = 0; i < visitsArr.length; i++) {
      let unitName = visitsArr[i].c[1]; // get unit Name
      let status = "";
      let r = visitsArr[i].r;
      //start with visits in marakez

      let visitsObj = {
        mar: {
          totalKM: visitsArr[i].c[6],
          totalDuration: visitsArr[i].c[5],
          eh: "",
          parkings: "",
          details: []
        }
      };
      // console.log(' r of visits array');
      // console.log(r)
      for (let j = 0; j < r.length; j++) {
        let dtArr3 =
          typeof r[j].c[3] == "object"
            ? r[j].c[3].t.split(" ")
            : r[j].c[3].split(" ");
        let dtArr4 =
          typeof r[j].c[4] == "object"
            ? r[j].c[4].t.split(" ")
            : r[j].c[4].split(" ");
        // console.log('in array')
        // console.log(dtArr3)
        // console.log('out array')
        // console.log(dtArr4)
        let detailsObj = {
          in: dtArr3[1] + " " + dtArr3[2], // in
          out: dtArr4[1] + " " + dtArr4[2], // out
          duration: r[j].c[5], // dur
          km: r[j].c[6] // km
        };
        // console.log('details obj')
        // console.log(detailsObj)
        visitsObj.mar.details.push(detailsObj);
      }

      // now update object of unit in final array
      let index = finalArr.findIndex(e => e.unitName === unitName);
      if (index >= 0) {
        finalArr[index].visits.mar = visitsObj.mar;
      } else {
        // console.log('not found')
      }
    }

    // console.log('with visits now ')
    // console.log(finalArr)
    this.setState({ newData: finalArr });
  }

  fillVisitsForUnit(totalArr, visitsArr) {
    //console.log('in vis arr, temp data is : ')
    // console.log(this.state.tempData)
    let finalArr = this.state.tempData; // get temp array data
    // fill total from total array to final object for unit [0] , selelcted unit
    if (!totalArr) {
      return;
    }
    if (!Array.isArray(totalArr)) {
      return;
    }

    finalArr[0].visits.mar.totalKM = totalArr[5];
    finalArr[0].visits.mar.totalDuration = totalArr[4];

    // console.log('unit mode final arr before start visits proccessing is ')
    // console.log(finalArr)
    // console.log('1- in units fill visits array')
    // console.log(visitsArr)
    for (let i = 0; i < visitsArr.length; i++) {
      let dtArr3 = visitsArr[i].c[2].t.split(" ");
      let dtArr4 = visitsArr[i].c[3].t.split(" ");
      let detailsObj = {
        in: dtArr3[1] + " " + dtArr3[2], // in
        out: dtArr4[1] + " " + dtArr4[2], // out
        duration: visitsArr[i].c[4], // dur
        km: visitsArr[i].c[5] // km
      };
      finalArr[0].visits.mar.details.push(detailsObj);
      // now update object of unit in final array
    }

    // console.log('unit : with visits now ')
    // console.log(finalArr)
    this.setState({ newData: finalArr });
  }
  fillParkings(pargkingsArr) {
    let finalArr = this.state.tempData;
    // console.log('final arr before start parkings proccessing is ')
    // console.log(finalArr)

    for (let i = 0; i < pargkingsArr.length; i++) {
      let unitName = pargkingsArr[i].c[1]; // get unit Name
      let status = "";
      let parkingCount = pargkingsArr[i].c[2]; // count of visits to markaz

      let index = finalArr.findIndex(e => e.unitName === unitName);
      if (index >= 0) {
        finalArr[index].visits.mar.parkings = parkingCount;
      }
    }

    // console.log('with parkings now ')
    // console.log(finalArr)
    this.setState({ newData: finalArr });
  }
  fillParkingsForUnit(totalArr, pargkingsArr) {
    if (!totalArr) {
      return;
    }
    if (!Array.isArray(totalArr)) {
      return;
    }
    let finalArr = this.state.tempData;
    finalArr[0].visits.mar.parkings = "";
    if (totalArr) {
      if (Array.isArray(totalArr)) {
        finalArr[0].visits.mar.parkings = totalArr[1];
      }
    }

    // console.log('with parkings now ')
    // console.log(finalArr)
    this.setState({ newData: finalArr });
  }

  fillEH(ehArr) {
    let finalArr = this.state.tempData;
    // console.log('final arr before start eh proccessing is ')
    // console.log(finalArr)

    for (let i = 0; i < ehArr.length; i++) {
      let unitName = ehArr[i].c[1]; // get unit Name
      let status = "";
      let ehValue = ehArr[i].c[2]; // get engine hours

      let index = finalArr.findIndex(e => e.unitName === unitName);
      if (index >= 0) {
        finalArr[index].visits.mar.eh = ehValue;
      }
    }

    // console.log('with eh now ')
    // console.log(finalArr)
    this.setState({ newData: finalArr });
  }
  fillEHForUnit(totalArr, ehArr) {
    let finalArr = this.state.tempData;
    // console.log('final arr before start eh proccessing is ')
    // console.log(finalArr)
    if (!totalArr) {
      return;
    }
    if (!Array.isArray(totalArr)) {
      return;
    }
    finalArr[0].visits.mar.eh = totalArr[1];

    // console.log('unit with eh now ')
    // console.log(finalArr)
    this.setState({ newData: finalArr });
  }

  fillMakbas(makbasArr) {
    let finalArr = this.state.tempData;
    // console.log('final arr before start makbas proccessing is ')
    // console.log(finalArr)

    for (let i = 0; i < makbasArr.length; i++) {
      let unitName = makbasArr[i].c[1]; // get unit Name
      let r = makbasArr[i].r;
      //start with visits in marakez

      let mak = {
        totalDuration: makbasArr[i].c[5], // get total duration
        details: []
      };

      for (let j = 0; j < r.length; j++) {
        let dtArr3 = r[j].c[3].t ? r[j].c[3].t.split(" ") : "-";
        let dtArr4 = r[j].c[4].t ? r[j].c[4].t.split(" ") : "-";
        let detailsObj = {
          in: dtArr3 == "-" ? "-" : dtArr3[1] + " " + dtArr3[2], // in
          out: dtArr4 == "-" ? "-" : dtArr4[1] + " " + dtArr4[2], // out
          duration: r[j].c[5] // dur
        };
        mak.details.push(detailsObj);
      }

      // now update object of unit in final array
      let index = finalArr.findIndex(e => e.unitName === unitName);
      if (index >= 0) {
        finalArr[index].visits.mak = mak;
      }
    }

    // console.log('with makbas  now ')
    // console.log(finalArr)
    this.setState({ newData: finalArr });
  }

  fillMakbasForUnit(makbasArr) {
    let finalArr = this.state.tempData;
    // console.log('final arr before start eh proccessing is ')
    for (let i = 0; i < makbasArr.length; i++) {
      finalArr[0].visits.mak.details.push({
        in: makbasArr[i].c[2].t.split(" ")[1], // in
        out: makbasArr[i].c[3].t.split(" ")[1], // in
        duration: makbasArr[i].c[4] //
      });
    }

    // console.log('unit with eh now ')
    // console.log(finalArr)
    this.setState({ newData: finalArr });
  }

  fillMahata(mahataArr) {
    let finalArr = this.state.tempData;
    // console.log('final arr before start mahata proccessing is ')
    // console.log(finalArr)

    for (let i = 0; i < mahataArr.length; i++) {
      let unitName = mahataArr[i].c[1]; // get unit Name
      let r = mahataArr[i].r;
      //start with visits in marakez

      let mah = {
        totalDuration: mahataArr[i].c[5], // get total duration
        details: []
      };

      for (let j = 0; j < r.length; j++) {
        let dtArr3 = r[j].c[3].t ? r[j].c[3].t.split(" ") : "-";
        let dtArr4 = r[j].c[4].t ? r[j].c[4].t.split(" ") : "-";
        let detailsObj = {
          in: dtArr3 == "-" ? "-" : dtArr3[1] + " " + dtArr3[2], // in
          out: dtArr4 == "-" ? "-" : dtArr4[1] + " " + dtArr4[2], // out
          duration: r[j].c[5] // dur
        };
        mah.details.push(detailsObj);
      }

      // now update object of unit in final array
      let index = finalArr.findIndex(e => e.unitName === unitName);
      if (index >= 0) {
        finalArr[index].visits.mah = mah;
      }
    }

    // console.log('with mahata  now ')
    // console.log(finalArr)
    this.setState({ newData: finalArr });
  }

  fillMahataForUnit(mahataArr) {
    let finalArr = this.state.tempData;
    // console.log('final arr before start eh proccessing is ')
    for (let i = 0; i < mahataArr.length; i++) {
      finalArr[0].visits.mah.details.push({
        in: mahataArr[i].c[2].t.split(" ")[1], // in
        out: mahataArr[i].c[3].t.split(" ")[1], // in
        duration: mahataArr[i].c[4] //
      });
    }

    // console.log('unit with eh now ')
    // console.log(finalArr)
    this.setState({ newData: finalArr });
  }

  fromDateOnChange(e) {
    let from = this.convertSATToUnix(e);
    if (!this.state.fromDateVal === "") {
      if (e) {
        if (this.compareTime(from, this.state.toDateValUnix)) {
          this.setState({ fromDateValUnix: from, fromDateVal: e });
        } else {
          this.setState({
            showAlert: true,
            error: true,
            alertMSG: "يجب ان تكون الفترة المحددة  صحيحة"
          });
        }
      }
    } else {
      this.setState({ fromDateValUnix: from, fromDateVal: e });
    }
  }
  toDateOnChange(e) {
    let to = this.convertSATToUnix(e);
    if (e) {
      if (this.compareTime(this.state.fromDateValUnix, to)) {
        this.setState({ toDateValUnix: to, toDateVal: e });
      } else {
        this.setState({
          showAlert: true,
          error: true,
          alertMSG: "يجب ان تكون الفترة المحددة  صحيحة"
        });
      }
    }
  }
  convertSATToUnix(sat) {
    let longVal = Math.floor(new Date(sat).getTime() / 1000);
    return longVal;
  }
  compareTime(from, to) {
    return from <= to;
  }
  propOpenFunc(e) {
    this.setState({
      showAlert: !this.state.showAlert,
      alertMSG: "",
      error: false
    });
  }

  async onTargetTypeChange(e) {
    if (e) {
      let selObj =
        e.id === 0
          ? this.state.units.length > 0
            ? this.state.units[0]
            : {}
          : this.state.unitGroups.length > 0
            ? this.state.unitGroups[0]
            : {};

      await this.setState({
        selectedTargetType: e,
        newData: [],
        selectedObject: selObj
      });
      this.findResource(this.state.res);
    }
  }

  onUnitChange(e) {
    if (e) {
      this.setState({
        selectedObject: e,
        selectedTargetType: fileJSON.targetTypes[0]
      });
      this.prepareTempData(e);
    }
  }
  onZoneChange(e) {
    // console.log(this.state)
    if (e) {
      this.setState({ selectedZone: e });
    }
  }
  onUnitGroupChange(e) {
    // console.log(e)
    if (e) {
      this.setState({
        selectedObject: e,
        selectedTargetType: fileJSON.targetTypes[1]
      });
      this.prepareTempData(e);
    }
  }

  prepareTempData(e) {
    // console.log(e)
    if (this.state.selectedTargetType.id == 1) {
      // groups
      let filteredUnits = this.state.units.filter(obj =>
        e.$$user_units.includes(obj._id)
      );
      // console.log('filtered units are : ')
      // console.log(filteredUnits)

      this.initFinalObject(filteredUnits);
    }
    if (this.state.selectedTargetType.id == 0) {
      let filteredUnits = [];
      filteredUnits.push(e);
      // console.log('filtered units is')
      // console.log(filteredUnits)
      this.initFinalObject(filteredUnits);
    }
  }
  initFinalObject(filteredUnits) {
    let arr = [];
    for (let i = 0; i < filteredUnits.length; i++) {
      arr.push({
        id: filteredUnits[i]._id,
        unitName: filteredUnits[i].$$user_name,
        status: "",
        visits: {
          mar: {
            totalKM: "",
            totalDuration: "",
            eh: "",
            parkings: "",
            details: [
              //  {
              //   in: '',
              //   out: '',
              //   duration: '',
              //   km: '',
              //   eh: '',
              //  }
            ]
          },
          mak: {
            details: [
              // {
              //   in: '',
              //   out: '',
              //   duration: ''
              // }
            ]
          },
          mah: {
            details: [
              // {
              //   in: '',
              //   out: '',
              //   duration: ''
              // }
            ]
          }
        }
      });
    }
    this.setState({ tempData: arr });
  }

  async calcCol(month, year) {
    let arr = [];
    let n = new Date(year, month, 0).getDate();
    arr.push({
      day: "Unit Name ",
      km:
        this.state.selectedTargetType.id === 0
          ? this.state.selectedObject.$$user_name
          : ""
    });
    for (let index = 0; index < n; index++) {
      if (this.state.selectedTargetType.id === 0) {
        // unit
        arr.push({ day: "" + (index + 1), km: "" });
      }
      if (this.state.selectedTargetType.id === 1) {
        // console.log('calculation for group')

        arr.push({ day: "" + (index + 1), km: "" });
      }
    }
    // console.log('structure arrays is : ')
    // console.log(arr)
    await this.setState({ columns: arr });
  }
  transpos(oldData) {
    // console.log('old data is ')
    let newArr = [];
    let object1 = [];
    let object2 = [];
    let data = oldData[0].data;
    for (let index = 0; index < data.length; index++) {
      object1.push(data[index].day);
      object2.push(data[index].km);
    }
    newArr.push(object1);
    newArr.push(object2);

    // console.log('new arrrrrray ')
    // console.log(newArr)
    return newArr;
  }
  transposForGroup(data) {
    //console.log('old data is ')
    let newArr = [];
    let newColumns = [];
    for (let index = 0; index < this.state.columns.length; index++) {
      newColumns.push(this.state.columns[index].day);
    }

    newArr.push(newColumns);

    for (let index = 0; index < data.length; index++) {
      let arr = [];
      arr.push(data[index].c[1]); // get unit name and put it in first col of array

      // then we should iterate through columns and try to find km value of days
      for (let j = 1; j <= newColumns.length; j++) {
        let itemIndex = data[index].r.findIndex(function (v, i) {
          // console.log(v.c[1])
          // console.log(newColumns[j])
          return v.c[1] === "Day " + newColumns[j]; // ex : if Day 1 === Day 1
        });

        if (itemIndex >= 0) {
          arr.push(data[index].r[itemIndex].c[2]); // get km
        } else {
          arr.push(""); // get km
        }
      }
      newArr.push(arr);
    }
    // console.log('final array in case of group for export excel is ')
    // console.log(newArr)
    return newArr;
  }

  export(e) {
    //Creating New Workbook
    let workbook = new ExcelJS.Workbook();

    //Creating Sheet for that particular WorkBook
    let sheetName = "التفاصيل";
    let sheet = workbook.addWorksheet(sheetName, {
      pageSetup: { paperSize: 9, orientation: "landscape" }, // 9 is A4 page
      properties: { showGridLines: true }
    });

    // sheet.getRow(3).values = ["name","state","markaz1","markaz2","markaz3","markaz_eh","markaz_parkings","makbas1","makbas2","makbas3",
    // "mah1","mah2","mah3"]
    sheet.mergeCells("A1:N2"); // merge name vertically // title of report

    //Header must be in below format
    sheet.getRow(3).values = [
      "اسم المركبة",
      "الحضور",
      "المركز البلدي", // c
      "المركز البلدي", // d
      "المركز البلدي", // e
      "المركز البلدي", // e

      "المحرك", // f
      "التوقفات", // g

      "المكبس", // h
      "المكبس", // i
      "المكبس", // j

      "ألمحطة التحويلية", // k
      "ألمحطة التحويلية", // l
      "ألمحطة التحويلية" // m
    ];
    sheet.getRow(4).values = [
      "اسم المركبة",
      "الحضور",
      "الدخول", // c
      "الخروج", // d
      "المدة", // e
      "المسافة", // f

      "المحرك", // g
      "التوقفات", // h

      "الدخول", // i
      "الخروج", // j
      "المدة", // k

      "الدخول ", // l
      "الخروج", // m
      "المدة" // n
    ];
    sheet.columns = [
      { key: "name", width: 22 },
      { key: "status", width: 5 },
      { key: "markaz1", width: 10 }, // c
      { key: "markaz2", width: 10 }, // d
      { key: "markaz3", width: 6 }, // e
      { key: "markaz4", width: 7 }, // f
      { key: "markaz_eh", width: 7 }, // g
      { key: "markaz_parkings", width: 3 }, // h
      { key: "makbas1", width: 10 }, // i
      { key: "makbas2", width: 10 }, // J
      { key: "makbas3", width: 8 }, // k
      { key: "mah1", width: 10 }, // l
      { key: "mah2", width: 10 }, // m
      { key: "mah3", width: 8 } // n
    ];

    sheet.mergeCells("A3:A4"); // merge name vertically
    sheet.mergeCells("B3:B4"); // merge state
    sheet.mergeCells("C3:F3"); // mergce center details
    sheet.mergeCells("I3:K3"); // merge makbas
    sheet.mergeCells("L3:N3"); // merge mahatah cells
    sheet.mergeCells("G3:G4"); // merge eh
    sheet.mergeCells("H3:H4"); // merge parkings

    let fn =
      "تقرير " +
      this.state.selectedObject.$$user_name +
      " - () - " +
      this.state.selectedZone.n +
      " - " +
      new Date(this.state.fromDateVal).toLocaleDateString();

    sheet.getCell("A1").value = fn;
    sheet.getCell("A1").font = { size: 18, bold: true };
    sheet.getCell("A1").border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" }
    };
    sheet.getCell("A1").alignment = {
      vertical: "middle",
      horizontal: "center"
    };

    sheet
      .getRow(3)
      .eachCell({ includeEmpty: false }, function (cell, colNumber) {
        cell.font = { size: 12, bold: true };
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" }
        };
        cell.alignment = { vertical: "middle", horizontal: "center" };
      });

    sheet.getRow(4).eachCell({ includeEmpty: true }, function (cell, colNumber) {
      cell.font = { size: 12, bold: true };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" }
      };

      cell.alignment = { vertical: "middle", horizontal: "center" };
    });

    let that = this;

    let dt = this.state.newData;
    // write the data
    let index = 5;
    for (let i in dt) {
      if (i != 0) {
        if (i % 30 === 0) {
          sheet.getRow(index).addPageBreak();
        }
      }

      sheet.addRow({ name: dt[i].unitName, status: dt[i].status });

      let marLength = dt[i].visits.mar.details.length;
      let makLength = dt[i].visits.mak.details.length;
      let mahLength = dt[i].visits.mah.details.length;

      let max = Math.max(...[marLength, makLength, mahLength]);
      // console.log(dt[i].unitName)
      // console.log(max)
      if (max === 0) {
        // sheet.getRow(index).eachCell({ includeEmpty: true }, function(cell, colNumber) {
        //  cell.style = that.styleCell()
        // });
        index++;
        continue;
      }

      sheet.mergeCells("A" + index + ":" + "A" + (index + (max - 1))); // unit name
      // sheet.getCell('A' + index).style = this.styleCell()

      sheet.mergeCells("B" + index + ":" + "B" + (index + (max - 1))); // status
      // sheet.getCell('B' + index).style = this.styleCell()

      sheet.mergeCells("G" + index + ":" + "G" + (index + (max - 1))); // eh
      // sheet.getCell('F' + index).style = this.styleCell()

      sheet.mergeCells("H" + index + ":" + "H" + (index + (max - 1))); // parkings
      // sheet.getCell('G' + index).style = this.styleCell()

      sheet.getCell("G" + index).value = dt[i].visits.mar.eh;

      sheet.getCell("H" + index).value = dt[i].visits.mar.parkings;

      if (dt[i].visits.mar.details.length > 0) {
        for (
          let j = 0 + index, counter = 0;
          j < dt[i].visits.mar.details.length + index;
          j++ // -1 because for iterations include first row
        ) {
          sheet.getCell("C" + j).value = dt[i].visits.mar.details[counter].in;
          // sheet.getCell('C'+j).style =this.styleCell()
          sheet.getCell("D" + j).value = dt[i].visits.mar.details[counter].out;
          // sheet.getCell('D'+j).style = this.styleCell()
          sheet.getCell("E" + j).value =
            dt[i].visits.mar.details[counter].duration;
          // sheet.getCell('E'+j).style = this.styleCell()
          sheet.getCell("F" + j).value = dt[i].visits.mar.details[counter].km;

          counter++;
        }
      }
      if (dt[i].visits.mak.details.length > 0) {
        for (
          let j = 0 + index, counter = 0;
          j < dt[i].visits.mak.details.length + index;
          j++ // -1 because for iterations include first row
        ) {
          sheet.getCell("I" + j).value = dt[i].visits.mak.details[counter].in;
          // sheet.getCell('H' + j).style=this.styleCell()
          sheet.getCell("J" + j).value = dt[i].visits.mak.details[counter].out;
          // sheet.getCell('I' + j).style=this.styleCell()

          sheet.getCell("K" + j).value =
            dt[i].visits.mak.details[counter].duration;
          // sheet.getCell('J' + j).style=this.styleCell()

          counter++;
        }
      }
      if (dt[i].visits.mah.details.length > 0) {
        for (
          let j = 0 + index, counter = 0;
          j < dt[i].visits.mah.details.length + index;
          j++ // -1 because for iterations include first row
        ) {
          sheet.getCell("L" + j).value = dt[i].visits.mah.details[counter].in;
          // sheet.getCell('K' + j).style=this.styleCell()

          sheet.getCell("M" + j).value = dt[i].visits.mah.details[counter].out;
          // sheet.getCell('L' + j).style=this.styleCell()

          sheet.getCell("N" + j).value =
            dt[i].visits.mah.details[counter].duration;
          // sheet.getCell('M' + j).style=this.styleCell()

          counter++;
        }
      }
      sheet
        .getRow(index)
        .eachCell({ includeEmpty: true }, function (cell, colNumber) {
          cell.style = that.styleCell();
        });
      index = index + max;
    }
    // border all
    sheet.eachRow(function (row, rowNumber) {
      if (rowNumber > 4) row.style = that.styleCell();
      row.eachCell(function (cell, rowNumberCell) {
        if (rowNumberCell < 14) {
          // console.log(rowNumberCell)
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" }
          };
          cell.alignment = { vertical: "top", horizontal: "center" };
        }
      });
    });

    let fileName = fn + ".xlsx";
    let str =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

    workbook.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data], { type: str });
      saveAs(blob, fileName);
    });
  }

  styleCell() {
    let style = {
      font: { size: 10, bold: false, name: "Arial" },
      alignment: { vertical: "middle", horizontal: "center" },
      border: {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" }
      }
    };
    return style;
  }

  calculateStatus() {
    let dt = this.state.newData;
    for (let i = 0; i < dt.length; i++) {
      if (
        dt[i].visits.mar.details.length === 0 &&
        dt[i].visits.mak.details.length === 0 &&
        dt[i].visits.mah.details.length === 0
      ) {
        dt[i].status = "غ";
      } else {
        dt[i].status = "ح";
      }
    }
    this.setState({ newData: dt });
  }
}

export default App;
